import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './modules/shared/shared.module';
import { AddhitComponent } from './addhit/addhit.component';
import { HitsComponent } from './hits/hits.component';
import { LoginComponent } from './login/login.component';
import { HitsListComponent } from './hits-list/hits-list.component';

@NgModule({
  declarations: [
    AppComponent,
    AddhitComponent,
    HitsComponent,
    LoginComponent,
    HitsListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,


  ],
  providers: [{ provide: FIREBASE_OPTIONS, useValue: environment.firebase },
            provideFirebaseApp(() => initializeApp(environment.firebase))    ,
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()) ],
  bootstrap: [AppComponent]
})
export class AppModule { }
