import { Component, OnInit } from '@angular/core';
import { HitsService } from '../services/hits.service';
import { Observable } from 'rxjs';
import { Hit } from '../models/hit';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'bb-hits',
  templateUrl: './hits.component.html',
  styleUrls: ['./hits.component.scss']
})
export class HitsComponent implements OnInit {
  hits$: Observable<Hit[]>;
  columnsToDisplay = [
    'number',
    'when',
    /*'odometer',*/
    'distance',
    'volume',
    'consumption',
    /* 'price', */
   /*  'comment'*/
  ];

  constructor(private hits: HitsService, private router: Router) {}

  ngOnInit() {
    this.hits$ = this.hits.list().pipe(
      map(changes => {
        let prevHit: Hit;
        let count = changes.length;
        return changes.map(d => {
          const when = d.when;
          // tslint:disable-next-line:no-string-literal
          if (when['toDate']) {
            // tslint:disable-next-line:no-string-literal
            d.when = when['toDate']();
            d.idx = count--;
            if (prevHit) {
              const timeBetween = prevHit.when.getTime() - d.when.getTime();
              prevHit.daysSince = (Math.round(timeBetween / (24 * 3600 * 1000)));
              prevHit.distPerDay = prevHit.distance / prevHit.daysSince;
            }
          }
          if (d.volume && d.distance) {
            d.consumption = (d.volume / d.distance) * 100;
          }
          prevHit = d;
          return d;
        });
      })
    );
  }

  delete(hit) {
    this.hits.delete(hit.id);
  }

  add() {
    this.router.navigate(['hit']);
  }
}
