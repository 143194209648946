import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'bb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) {}
  login() {
    this.auth.doGoogleLogin().then(() => {
      this.router.navigate(['']);
    });
  }

  ngOnInit() {
  }

}
