// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBd-EJ0tRDa69ebzCn8TRSbzLQgiPH3iFg',
    authDomain: 'bensinbokin.firebaseapp.com',
    databaseURL: 'https://bensinbokin.firebaseio.com',
    projectId: 'bensinbokin',
    storageBucket: 'bensinbokin.appspot.com',
    messagingSenderId: '887373621008',
    appId: '1:887373621008:web:0e1bd7d22ae78191892795',
    measurementId: 'G-MDY8WQK6RH'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
