import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'bb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bensínið v18.1.0';

  constructor(private auth: AuthService, private router: Router) {}
  login() {
    this.auth.doGoogleLogin().then(() => {
      this.router.navigate(['']);
    });
  }
  logout() {
    console.log('Logging out'),
      this.auth
        .logout()
        .then(() => {
          this.router.navigate(['login']);
        })
        .catch(() => {});
  }

  isLoggedIn(): Observable<any> {
    return this.auth.currentUserObservable;
  }
}
