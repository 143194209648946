<mat-toolbar>
  <span>{{title}}</span>
  <span class="spacer"></span>
  <div *ngIf="isLoggedIn() | async as user; else showLogin">
    <img slot="start" *ngIf="user" class="avatar" [src]="user.photoURL" title="{{user.displayName}} ({{user.email}})" referrerpolicy=“no-referrer”/>
    <button mat-icon-button (click)="logout()"><mat-icon>logout</mat-icon></button>

  </div>
  <ng-template #showLogin>
    <button  mat-raised-button (click)="login()">Login</button>
  </ng-template>
</mat-toolbar>
<router-outlet></router-outlet>
