import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Hit } from '../models/hit';
import { HitsService } from '../services/hits.service';

@Component({
  selector: 'bb-hits-list',
  templateUrl: './hits-list.component.html',
  styleUrls: ['./hits-list.component.scss']
})
export class HitsListComponent {

  focusRow = -1;

  hits$: Observable<Hit[]>;

  totalAccum = 0;

  constructor(private hits: HitsService, private router: Router){
    this.hits$ = this.hits.list().pipe(
      map(changes => {
        let prevHit: Hit;
        let count = changes.length;
        this.totalAccum = 0;
        return changes.map(d => {
          const when = d.when;
          // tslint:disable-next-line:no-string-literal
          if (when['toDate']) {
            // tslint:disable-next-line:no-string-literal
            d.when = when['toDate']();
            d.idx = count--;
            if (prevHit) {
              const timeBetween = prevHit.when.getTime() - d.when.getTime();
              prevHit.daysSince = (Math.round(timeBetween / (24 * 3600 * 1000)));
              prevHit.distPerDay = prevHit.distance / prevHit.daysSince;
            }

            d.total = d.price * d.volume;
            this.totalAccum += d.total;
          }
          if (d.volume && d.distance) {
            d.consumption = (d.volume / d.distance) * 100;
          }
          prevHit = d;
          return d;
        });
      })
    );
  }

  add() {
    this.router.navigate(['hit']);
  }

  split(v: string|number): string[] {
    return `${v}`.split('');
  }

  focus(idx: number) {
    this.focusRow = idx;
  }

}
