<section fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxFlex="500px" fxFlex.xs="100%">
    <form [formGroup]="hitForm" autocomplete="off" novalidate (ngSubmit)="createHit(hitForm.value)" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
      <mat-card-content>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="when" id="when" readonly (click)="picker.open()" />
          <mat-hint align="end">Date of purchase</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="number" placeholder="Volume" formControlName="volume" id="volume" />
          <mat-hint align="end">Enter volume in liters</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="number" placeholder="Odometer" formControlName="odometer" id="odometer" />
          <mat-hint align="end">Enter odometer value ({{lastOdo$ | async}})</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="number" placeholder="Distance" formControlName="distance" id="distance" />
          <mat-hint align="end">Enter distance driven @if(lastDist()>0){<strong>({{lastDist()}})</strong>}</mat-hint>
          <button type="button" mat-icon-button matSuffix [disabled]="lastDist()<1" (click)="setDistance(lastDist())"><mat-icon style="color:darkgreen">check</mat-icon></button>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="number" readonly="false" placeholder="Price" formControlName="price" id="price" />
          <mat-hint align="end">Price (optional)</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <textarea matInput type="text"placeholder="Enter a comment" formControlName="comment" id="comment" ></textarea>
          <mat-hint align="end">Enter a comment</mat-hint>
        </mat-form-field>

        <mat-checkbox formControlName="showLocation">Include location</mat-checkbox>
      </mat-card-content>

      <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="!hitForm.valid">Create</button>
        <button type="button" mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
      </mat-card-actions>
    </form>
  </mat-card>
</section>
