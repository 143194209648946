import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: firebase.User = null;
  userData: any = null; // Save logged in user data

  constructor(private af: AngularFireAuth) {
    this.af.authState.subscribe((auth) => {
      this.user = auth;
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.af.signInWithPopup(provider).then(res => {
        resolve(res);
        this.userData = res;
      });
    });
  }

  logout() {
    return this.af.signOut();
  }

// Returns true if user is logged in
get authenticated(): boolean {
  return this.user !== null;
}

  get currentUserObservable(): any {
    return this.af.authState;
  }
}
