import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { Hit, Location } from '../models/hit';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class HitsService extends BaseService<Hit> {
  private location: Location = {};

  constructor(afs: AngularFirestore) {
    super('hits', afs);
    this.initLocation();
  }

  initLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.location.longitude = position.coords.longitude;
        this.location.latitude = position.coords.latitude;
      });
    } else {
      this.location = {};
    }
  }

  getLocation(): Location {
    return this.location;
  }
}
