/* https://medium.com/@dneimke/generic-firebase-data-access-63ebd0506d53 */

import { Injectable } from '@angular/core';
import { IBaseService } from './ibase';
import { BaseEntity } from 'src/app/models/base-entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/compat/firestore';

export abstract class BaseService<T extends BaseEntity> implements IBaseService<T>  {

  protected collection: AngularFirestoreCollection<T>;

  constructor(path: string, protected afs: AngularFirestore) {
    this.collection = this.afs.collection(path, ref => ref.orderBy('when', 'desc'));
  }

  public get(identifier: string): Observable<T> {
    return this.collection
      .doc<T>(identifier)
      .snapshotChanges()
      .pipe(
        map(doc => {
          if (doc.payload.exists) {
            const data = doc.payload.data() as any;
            const id = doc.payload.id;
            return { id, ...data };
          }
        })

      );
  }

  public list(): Observable<T[]> {

    return this.collection
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  public add(item: T): Promise<T> {

    const promise = new Promise<T>((resolve, reject) => {
      this.collection.add(item).then(ref => {
        const newItem = {
          id: ref.id,
          /* workaround until spread works with generic types */
          ...(item as any)
        };
        resolve(newItem);
      });
    });
    return promise;
  }


  public update(item: T): Promise<T> {

    const promise = new Promise<T>((resolve, reject) => {
      const docRef = this.collection
        .doc<T>(item.id)
        .set(item)
        .then(() => {
          resolve({
            ...(item as any)
          });
        });
    });
    return promise;
  }

  public delete(id: string): void {
    const docRef = this.collection.doc<T>(id);
    docRef.delete();
  }

}
