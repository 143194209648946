import { Component, OnInit, signal } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Hit } from '../models/hit';
import { HitsService } from '../services/hits.service';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'bb-addhit',
  templateUrl: './addhit.component.html',
  styleUrls: ['./addhit.component.scss']
})
export class AddhitComponent implements OnInit {
  public hitForm: FormGroup;

  lastOdoNumber = -1;
  lastOdo$: Observable<number>;
  lastDist = signal(-1);

  constructor(private hits: HitsService, private router: Router) {}

  ngOnInit() {
    this.hitForm = new FormGroup({
      when: new FormControl(new Date()),
      volume: new FormControl('', [Validators.required]),
      distance: new FormControl('', [Validators.required]),
      odometer: new FormControl(''),
      price: new FormControl(''),
      comment: new FormControl(''),
      showLocation: new FormControl(true)
    });

    this.hitForm.valueChanges.subscribe((val) => {
      this.lastDist.set(val.odometer - this.lastOdoNumber);
    });

    this.lastOdo$ = this.hits.list().pipe(
      map(res => {
        if (res && res.length > 0) {
          this.lastOdoNumber = res[0].odometer;
          return res[0].odometer;
        }
        console.log(res);
        return -1;
      })
    );
  }

  createHit(hit: Hit) {
    // tslint:disable-next-line:no-string-literal
    if (hit['showLocation']) {
      hit.location = this.hits.getLocation();
    }
    console.log('Saving', hit);
    this.hits.add(hit).then(
      res => {
        this.router.navigate(['hits']);
      },
      err => {
        console.log(err);
      }
    );
  }

  onCancel() {
    this.router.navigate(['hits']);
  }

  setLocation() {
    this.hitForm.get('location').setValue(this.hits.getLocation());
  }

  setDistance(dist: number) {
    this.hitForm.get('distance').setValue(dist);
  }
}
