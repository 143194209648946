<button mat-fab (click)="add()" color="primary" id="add-button">
  <mat-icon>add</mat-icon>
</button>
<table mat-table [dataSource]="hits$">
  <ng_container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let hit">{{hit.idx}}</td>
  </ng_container>
  <ng-container matColumnDef="when">
    <th mat-header-cell *matHeaderCellDef>When</th>
    <td mat-cell *matCellDef="let hit">{{ hit.when | date: 'yyyy-MM-dd' }}</td>
  </ng-container>
<!--
  <mat-text-column name="odometer" justify="center"></mat-text-column>
-->
<ng-container matColumnDef="distance">
  <th mat-header-cell *matHeaderCellDef>Dist/Odo/Daily</th>
  <td mat-cell *matCellDef="let hit" >{{ hit.distance}} km<br/>({{hit.odometer}} km)<br/><span *ngIf="hit.distPerDay">({{hit.distPerDay | number : '1.1-1'}} km/day)</span></td>
</ng-container>



  <ng-container matColumnDef="volume">
    <th mat-header-cell *matHeaderCellDef>Vol.</th>
    <td mat-cell *matCellDef="let hit" >{{ hit.volume}} l</td>
  </ng-container>

  <ng-container matColumnDef="consumption">
    <th mat-header-cell *matHeaderCellDef>Consumption</th>
    <td mat-cell *matCellDef="let hit" >{{ hit.consumption | number : '1.1-2'}} l/100 km</td>
  </ng-container>
  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef>Price</th>
    <td mat-cell *matCellDef="let hit" >{{ hit.price | number : '1.1'}} kr.</td>
  </ng-container>

  <mat-text-column name="comment" ></mat-text-column>

  <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let hit" justify="end">
        <button mat-icon-button (click)="delete(hit)">X</button>
      </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</table>
