<h2>YM-Y19 <button mat-mini-fab color="warn" (click)="add()"><mat-icon>add</mat-icon></button></h2>
<div class="hit-container">
  @for(hit of hits$ | async; track $index){
    <div class="hit" (click)="focus(hit.idx)" [ngClass]="{'selected-row': hit.idx==focusRow}">
      <div class="hit-idx">
        {{hit.idx}}
      </div>
      <div class="hit-data" >
        <span class="hit-info-small" style="text-decoration: underline">{{hit.when | date:'E, dd.MM.yyyy'}} (+{{hit.daysSince}} dagar)</span>
        <span>{{hit.volume}} lítrar, Eyðsla: {{hit.consumption | number : '1.1-2'}} l/100km</span>
        <span>Eknir: {{hit.distPerDay  | number : '1.0-0'}} km/dag</span>
        <span class="hit-info-small" *ngIf="hit.idx==focusRow">Verð: {{hit.price | number : '1.1-2'}} kr</span>
        <span class="hit-info-small" *ngIf="hit.idx==focusRow">{{hit.total | number : '1.0-0'}} kr - {{totalAccum  | number : '1.0-0'}} kr síðan 2019</span>
        <span *ngIf="hit.idx==focusRow">
          <div class="odometer">
            @for(ch of split(hit.odometer); track $index){
              <div class="digit">{{ch}}</div>
            }
          </div>
          km
        </span>
      </div>
      <div class="hit-milage">
        <div class="odometer">
          @for(ch of split(hit.distance); track $index){
            <div class="digit">{{ch}}</div>
          }
        </div>
        km
      </div>
    </div>
  }
</div>
